/* Disabling hover effect for button */
.btn {
    &:hover {
      background-color: var(--bs-btn-bg);
      color: var(--bs-btn-color);
    }
    &:focus {
      background-color: var(--bs-btn-bg);
      color: var(--bs-btn-color);
    }
    &.active {
      &:hover {
        background-color: var(--bs-btn-active-bg);
        color: var(--bs-btn-active-color);
      }
      &:focus {
        background-color: var(--bs-btn-active-bg);
        color: var(--bs-btn-active-color);
      }
    }
}
